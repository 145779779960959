<template>
    <div v-if="product">
        <div class="h5">Selected Product</div>
        <hr />
        <div class="d-flex d-md-block flex-column align-items-center">
            <div class="text-capitalize">
                {{ product.title }}
            </div>
            <img
                class="img-thumbnail img-responsive"
                :src="imgsrc + product.image"
                :alt="product.title"
                style="max-width: 200px"
            />
        </div>
    </div>
</template>

<script>
import axios from "axios"
import request from "../../apis/request"

export default {
    props: {
        productId: null,
    },
    data() {
        return {
            product: null,
            imgsrc: axios.defaults.uploadURL,
        }
    },
    mounted() {
        this.fetchProduct()
    },
    methods: {
        async fetchProduct() {
            const res = await request.get("/single-product/" + this.productId)
            const data = res.data
            this.product = data
        },
    },
}
</script>

<style></style>
