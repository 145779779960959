<template>
    <div class="container py-5">
        <Loader :isLoading="isLoading" />
        <div class="row">
            <div class="col-md-6">
                <h4>Hire a material inspector</h4>
                <p>
                    Material inspectors help you by physically inspecting the product that you
                    request. Select the material inspectors available for this product.
                </p>
            </div>
            <div class="col-md-6">
                <EnquiredProduct v-if="enquiryDetails" :productId="enquiryDetails.product_id" />
            </div>
        </div>
        <hr />

        <MaterialTesters v-if="enquiryDetails" :productId="enquiryDetails.product_id" />
    </div>
</template>

<script>
import MaterialTesters from "./MaterialTesters.vue"
import EnquiredProduct from "./EnquiredProduct.vue"
import scrollTop from "../utils/scrollTop"
import request from "../../apis/request"
import Loader from "../Loader.vue"
import { mapState, mapGetters } from "vuex"

export default {
    components: { Loader, MaterialTesters, EnquiredProduct },
    data() {
        return {
            ticketId: this.$route.params.id,
            enquiryDetails: null,
            isLoading: false,
        }
    },
    async mounted() {
        if (this.isMaterialTester) {
            this.$toasted.error("You are not allowed to hire testers!")
            return this.$router.push({ name: "dashboard" })
        }
        scrollTop()
        this.isLoading = true
        try {
            await this.checkMaterialTesterAllowed()
            await this.getEnquiryDetails()
        } catch (err) {
            console.log("error in loading details", err)
        }
        this.isLoading = false
    },
    computed: {
        ...mapState("auth", {
            user: "user",
        }),
        ...mapGetters("auth", {
            isMaterialTester: "isMaterialTester",
        }),
    },
    methods: {
        async getEnquiryDetails() {
            request.get("/singleenquiredetails/" + this.ticketId).then((res) => {
                //   this.enquiredetails = res.data;
                this.enquiryDetails = res.data.enquire
                if (this.enquiryDetails.user_id === this.user.id) {
                    this.$toasted.error("You cannot hire testers for your own product!")
                    return this.$router.push({ name: "landing" })
                }
            })
        },
        async checkMaterialTesterAllowed() {
            try {
                const res = await request.get("/material-testers/check-material-tester-allowed")
                const allowed = res.data.result
            } catch (err) {
                console.log(err)
                const message = err.response && err.response.data && err.response.data.message
                this.$toasted.error(message || "Sorry, you are not allowed to access this feature!")

                // return this.$router.push({
                //     name: "landing",
                // })
            }
        },
    },
}
</script>

<style></style>
